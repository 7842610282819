"use client";


import { initializePaddle, Paddle, PaddleEventData } from '@paddle/paddle-js'; 
import React, { PropsWithChildren, use } from 'react';

type PaddleData = { 
  init: (params?: { eventCallback?: (event: PaddleEventData) => void }) => Promise<Paddle | null>,
};

const PaddleContext = React.createContext<PaddleData>({ 
  init: () => { return Promise.resolve(null); },
});

interface PaddleProps {
  clientKey: string;
  environment: string;
}

export function IndependentPaddleProvider(props: PropsWithChildren<PaddleProps>) {
  const { clientKey, environment } = props;

  const valueToPass = { 
    init: ({ eventCallback }: { eventCallback?: (event: PaddleEventData) => void } = {}) => {
      return initializePaddle({ token: clientKey, environment: environment as 'production' | 'sandbox', eventCallback  }).then(paddle => {
        return paddle!;
      });
    }
  };

  return (
    <PaddleContext.Provider value={valueToPass}>
      {props.children}
    </PaddleContext.Provider>
  );
}


export function usePaddle() {
  const paddle = use(PaddleContext);
  return paddle;
}