// app/providers.tsx
'use client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { env } from './env'

if (typeof window !== 'undefined') {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: true,
    capture_pageleave: true,
    autocapture: {
      dom_event_allowlist: ['click', 'change', 'submit'], // DOM events from this list ['click', 'change', 'submit']
      // url_allowlist: ['web.rtj.app./docs/.*'], // strings or RegExps
      // url_ignorelist can be used on its own, or combined with url_allowlist to further filter which URLs are captured
      // url_ignorelist: ['posthog.com./docs/.*/secret-section/.*'], // strings or RegExps
      element_allowlist: ['button', 'a', 'form', 'input', 'textarea'], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
      // css_selector_allowlist: ['[ph-autocapture]'], // List of CSS selectors
      element_attribute_ignorelist:['data-attr-pii="email"'], // List of element attributes to ignore
    },
  })
}

export function PosthogProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (<PostHogProvider client={posthog}>
    {children}
  </PostHogProvider>);
}
