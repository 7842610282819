import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const env = createEnv({
  server: {
    NEXT_VACANCIES_API_BASE_URL: z.string().url(),
    NEXT_PHOTON_API_BASE_URL: z.string().url(),
  },
 
  /**
   * The prefix that client-side variables must have. This is enforced both at
   * a type-level and at runtime.
   */
  clientPrefix: "NEXT_PUBLIC_",
 
  client: {
    NEXT_PUBLIC_PADDLE_CLIENT_KEY: z.string().min(1),
    NEXT_PUBLIC_PADDLE_ENVIRONMENT: z.enum(['sandbox','production']),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().url(),
  },
 
  /**
   * What object holds the environment variables at runtime. This is usually
   * `process.env` or `import.meta.env`.
   */
  runtimeEnv: {
    NEXT_VACANCIES_API_BASE_URL: process.env.NEXT_VACANCIES_API_BASE_URL,
    NEXT_PHOTON_API_BASE_URL: process.env.NEXT_PHOTON_API_BASE_URL,
    NEXT_PUBLIC_PADDLE_CLIENT_KEY: process.env.NEXT_PUBLIC_PADDLE_CLIENT_KEY,
    NEXT_PUBLIC_PADDLE_ENVIRONMENT: process.env.NEXT_PUBLIC_PADDLE_ENVIRONMENT,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  },
 
  /**
   * By default, this library will feed the environment variables directly to
   * the Zod validator.
   *
   * This means that if you have an empty string for a value that is supposed
   * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
   * it as a type mismatch violation. Additionally, if you have an empty string
   * for a value that is supposed to be a string with a default value (e.g.
   * `DOMAIN=` in an ".env" file), the default value will never be applied.
   *
   * In order to solve these issues, we recommend that all new projects
   * explicitly specify this option as true.
   */
  emptyStringAsUndefined: true,

  onValidationError(error) {
    throw error;
  },
});
